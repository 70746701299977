class ManageWidget {

    //Suggests a key based on either a client or project name
    static topform(component) {
        return [
          {
            id: 1,
            sections: [
              { 
                rows: [
                  {
                    components: [
                      {
                        name: "InputGenericCheckbox",
                        properties: {
                          class: "col-xl-12 px-5 mb-5",
                          id: 'input-published',
                          label: `${component.$t("FORMS.INPUTS.PUBLISH")}`,
                          model: component.$v.form.published,
                          submissionStates: component.submissionStates
                        }
                      }
                    ]
                  },
                  {
                    components: [
                      {
                        name: "InputGenericDropdown",
                        properties: {
                          class: "col-xl-6",
                          id: 'input-type',
                          label: `${component.$t("FORMS.INPUTS.WIDGET_TYPE")}`,
                          model: component.$v.form.system_widget_types_id,
                          options: component.availableWidgetTypes,
                          optionLabel: "name",
                          value: "id",
                          errorMessage: `${component.$t("FORMS.ERROR.WIDGET_TYPE")}`,
                          disabled: component.managementType == "update"
                        }
                      },
                      {
                        name: "InputGenericDropdown",
                        show: component.form.system_widget_types_id != 7,
                        properties: {
                          class: "col-xl-6",
                          id: 'input-location',
                          label: `${component.$t("FORMS.INPUTS.LOCATION")}`,
                          model: component.$v.form.placement,
                          options: component.availableLocations,
                          optionLabel: "name",
                          value: "id",
                          errorMessage: `${component.$t("FORMS.ERROR.LOCATION")}`
                        }
                      }
                    ]
                  },
                  {
                    show: component.form.system_widget_types_id == 1 || component.form.system_widget_types_id == 5,
                    components: [
                      {
                        name: "InputGeneric",
                        properties: {
                          id: 'input-title',
                          class: "col-12 mb-7",
                          alignLabel: "left",
                          showInline: false,
                          label: `${component.$t("FORMS.INPUTS.TITLE")}`,
                          placeholder: `${component.$t("FORMS.INPUTS.TITLE")}`,
                          model: component.$v.form.config.title,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.TITLE")}`,
                          type: "text"                     
                        }
                      }
                    ]
                  },
                  {
                    show: component.form.system_widget_types_id == 3,
                    components: [
                      {
                        name: "InputGenericRadioOption",
                        properties: {
                          class: "col-xl-4 px-5 mb-0",
                          id: 'input-widget-poll',
                          label: `${component.$t("FORMS.INPUTS.POLL")}`,
                          model: component.$v.form.config.poll,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.POLL")}`,
                          options: component.pollActivities,
                          value: "id",
                          text: "title"
                        }
                      }
                    ]
                  },
                  {
                    show: component.form.system_widget_types_id == 5,
                    components: [
                      {
                        name: "InputGeneric",
                        properties: {
                          class: "col-8 mb-7",
                          id: 'input-url',
                          alignLabel: "left",
                          showInline: false,
                          type: "text",
                          label: `${component.$t("FORMS.INPUTS.URL")}`,
                          placeholder: `${component.$t("FORMS.INPUTS.URL")}`,
                          model: component.$v.form.config.url,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.URL")}`
                        }
                      },
                        {
                        name: "InputGenericGroup",
                        properties: {
                          class: "col-xl-4 px-5 mb-7",
                          id: 'input-widget-height',
                          alignLabel: "left",
                          showInline: false,
                          type: "number",
                          label: `${component.$t("FORMS.INPUTS.HEIGHT")}`,
                          placeholder: `${component.$t("FORMS.INPUTS.HEIGHT")}`,
                          model: component.$v.form.config.height,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.HEIGHT")}`,
                          append: "px"
                        }
                      }
                    ]
                  },
                  {
                    show: component.form.system_widget_types_id == 1,
                    components: [
                      {
                        name: "InputGenericRichText",
                        properties: {
                          class: "col-xl-12 mb-7",
                          id: 'input-content',
                          label: `${component.$t("FORMS.INPUTS.CONTENT")}`,
                          model: component.$v.form.config.content,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.CONTENT")}`
                        }
                      }
                    ]
                  },
                  {
                    components: [
                      {
                        show: component.form.system_widget_types_id == 1,
                        name: "IntegratedDropzone",
                        on: {
                          asset_reservation_created: (value) => { component.$refs["widget-topform"].$emit('asset_reservation_created', value) },
                          asset_reservation_deleted: (value) => { component.$refs["widget-topform"].$emit('asset_reservation_deleted', value) },
                          remove_project_asset: (value) => { component.$refs["widget-topform"].$emit('remove_project_asset', value) },
                        },
                        properties: {
                          class: "col-xl-12 px-3",
                          assetReservation: component.assetReservationData,
                          idx: 1,
                          projectAssets: component.projectWidget.project_assets,
                          wholeWidth: true,
                          removable: true
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
    }
  
  }
  
export default ManageWidget;