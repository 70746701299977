<template>
  <div>
    <template v-for="(option, i) in options">
      <b-form-group
        :key="i"
        :id="id + '-option-' + i + '-group'"
        :label="option[label]"
        :label-sr-only="true"
        :label-for="id + '-option-' + i"
        class="mb-0"
      >
        <b-form-checkbox
          :id="option[value] + '-option'"
          :name="id + '-option-' + i"
          value="1"
          unchecked-value="0"
          size="lg"
          @change="checkboxChanged($event, option[value])"
        >
          <p class="pt-1 mb-2">{{ option[label] }}</p>
        </b-form-checkbox>
      </b-form-group>
    </template>
  </div>
</template>

<script>
export default {
  name: "GenericGroupedCheckbox",

  props: {
    id: { type: String, required: true },
    label: { type: String, required: true },
    value: { type: String, required: true },
    options: { type: Array, required: true },
    initial_selection: { type: Array, required: true }
  },

  data() {
    return {
      selected_options: []
    }
  },

  mounted() {
    this.initial_selection.forEach((selection) => {
      document.getElementById(selection + "-option").click();
    });
  },

  methods: {
    checkboxChanged: function(event, value) {
      if (event == 1) {
        this.selected_options.push(value);
      } else {
        if (this.selected_options.includes(value)) {
          const index = this.selected_options.indexOf(value);
          this.selected_options.splice(index, 1);
        }
      }
    },
    reset: function() {
      this.selected_options = [];
    }
  },

  watch: {
    selected_options: {
      handler(val) {
        this.$emit('selected_options_changed',val);
      },
      deep: true
    },
  }
};
</script>
