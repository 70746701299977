<template>
  <div class="row col-xl-12 mx-0 px-0">
    <!--start::TOPFORM-->
    <Topform
      ref="widget-topform"
      :managementType="managementType"
      :status="null"
      pageType="widget"
      :name="projectWidget.title"
      :errors="projectWidgetErrors"
      :submissionStates="submissionStates"
      parentPage="/project/setup/widgets"
      :tabs="computedTabs"
      :validation="computedvalidation"
      :loadingComplete="loadingComplete"
      @clear_errors="clearErrors()"
      @asset_reservation_created="form.config.media.push($event)"
      @asset_reservation_deleted="form.config.media = form.config.media.filter(function(stim) { return stim !== $event })"
      @remove_project_asset="form.media_remove.push($event)"
      @submit="submit"
      @clear_existing_data="clearData"
    >
    </Topform>
    <!--end::TOPFORM-->
    <div
      v-if="form.system_widget_types_id != 7"
      class="col-xl-4 pr-0 pl-2"
    >
      <div class="card card-custom gutter-b">
        <div class="card-body">
          <b-spinner
            v-if="loadingComplete == false"
            variant="primary"
            label="Spinning"
            class="card-loader"
          >
          </b-spinner>
          <div v-if="loadingComplete && !error.active">
            <h6 class="mb-2">{{ $t('SETUP.WIDGETS.VISIBILITY_TITLE') }}</h6>
            <p class="mb-10">{{ $t('SETUP.WIDGETS.VISIBILITY_DESC_' + form.placement) }}</p>
            <div v-show="form.placement == 2" class="mx-5">
              <label class="font-weight-bolder">Activities:</label>
              <InputGenericGroupedCheckbox
                id="input_activities"
                label="title"
                value="id"
                ref="activityChecklist"
                :initial_selection="initial_activity_selection"
                :options="eligiblePinActivities"
                @selected_options_changed="form.activities = $event"
                :submissionStates="submissionStates"
              >
              </InputGenericGroupedCheckbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, requiredIf, url } from "vuelidate/lib/validators";
import InputGenericGroupedCheckbox from "@/view/components/form-inputs/GenericGroupedCheckbox";
import PageLoader from "@/core/classes/PageLoader.js";
import Toast from "@/core/classes/Toast.js";

//Vuex Imports
import {
  POPULATE_PROJECT_WIDGETS,
  POPULATE_PROJECT_WIDGET,
  CREATE_PROJECT_WIDGET,
  UPDATE_PROJECT_WIDGET,
  CLEAR_PROJECT_WIDGET_ERRORS,
  CLEAR_PROJECT_WIDGET_INFO
} from "@/core/services/store/project/project_widgets.module";

import { POPULATE_ACTIVITY_LIST } from "@/core/services/store/activity/activity.module";

//Topform Imports
import TopformConfig from "@/core/config/topform/ManageWidget.js";

export default {
  mixins: [validationMixin],

  name: "ManageProjectWidget",

  data() {
    return {
      pageLoader: new PageLoader(2),
      toast: new Toast(),
      submissionStates: {
        submitting: false
      },
      error: {
        active: false,
        message: ""
      },
      initial_activity_selection: [],
      form: {
        published: 1,
        system_widget_types_id: 1,
        placement: 1,
        activities: [],
        media_remove: [],
        config: {
          title: "",
          content: "",
          media: [],
          poll: null,
          url: "",
          height: "120"
        }
      }
    }
  },

  validations: {
    form: {
      published: { required },
      system_widget_types_id: { required },
      placement: { required },
      config: {
        title: {
          required: requiredIf(function() {
            return [1,5].includes(this.form.system_widget_types_id)
          })
        },
        content: {},
        media: {},
        poll: {
          required: requiredIf(function() {
            return [3].includes(this.form.system_widget_types_id)
          })
        },
        url: {
          required: requiredIf(function() {
            return [5].includes(this.form.system_widget_types_id)
          }),
          url
        },
        height: {
          required: requiredIf(function() {
            return [5].includes(this.form.system_widget_types_id)
          })
        }
      }
    }
  },

  components: {
    InputGenericGroupedCheckbox
  },

  mounted() {
    this.fetchExistingWidgets();
    this.fetchActivities();

    if (this.$route.params.id != undefined) {
      this.pageLoader.addRequiredComponent();
      this.fetchExistingWidget();
    };
  },

  methods: {
    fetchActivities: function() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          paginate: false,
          activityType: 'with',
          activityStage: 'with',
          projectSegments: 'with'
        }
      };

      this.$store
        .dispatch(POPULATE_ACTIVITY_LIST, payload)
        .then(() => {
          this.pageLoader.setCompleteComponent();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project's activities, please check back later or contact the helpdesk";
        });
    },
    fetchExistingWidgets() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          systemWidgetType: "with",
        }
      }

      this.$store
        .dispatch(POPULATE_PROJECT_WIDGETS, payload)
        .then(() => {
          this.pageLoader.setCompleteComponent();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the widget list, please check back later or contact the helpdesk";
        });
    },
    fetchExistingWidget: function() {
      let payload = {
        projectId: this.projectInfo.id,
        id: this.$route.params.id,
        params: {
          activities: "with",
          projectWidgetConfigs: "with",
          poll: 'with',
          projectAssets: 'with',
          appendSignedUrls: 1,
          paginate: "false",
        }
      }

      this.$store
        .dispatch(POPULATE_PROJECT_WIDGET, payload)
        .then(() => {
          this.form.published = this.projectWidget.published;
          this.form.system_widget_types_id = this.projectWidget.system_widget_types_id;
          this.form.placement = this.projectWidget.placement;

          this.projectWidget.project_widget_configs.forEach((projectWidget) => {
            if (projectWidget.name != 'media') {
              this.form.config[projectWidget.name] = projectWidget.value;
            }
          });

          this.projectWidget.activities.forEach((activity) => {
            this.initial_activity_selection.push(activity.id);
          });

          this.pageLoader.setCompleteComponent();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the existing widget, please check back later or contact the helpdesk";
        });
    },
    clearData: function() {
      this.$store.dispatch(CLEAR_PROJECT_WIDGET_INFO)
    },
    submit: function() {
      this.clearErrors();

      this.submissionStates.submitting = true;

      if (this.managementType == "update") {
        this.submitUpdate();
      } else {
        this.submitPost();
      }
    },
    submitUpdate: function() {
      let payload = {
        projectId: this.projectInfo.id,
        id: this.$route.params.id,
        data: this.form
      }

      this.$store
        .dispatch(UPDATE_PROJECT_WIDGET, payload)
        .then(() => {
          this.toast.notify(this, `${this.$t("TOASTS.EDIT_WIDGET.MESSAGE")}`, `${this.$t("TOASTS.EDIT_WIDGET.TITLE")}`, 'success');
          this.$router.push({ name: 'setup_widgets' });
        })
        .catch(() => {})
        .finally(() => {
          this.submissionStates.submitting = false;
        });
    },
    submitPost: function() {
      let payload = {
        projectId: this.projectInfo.id,
        data: this.form
      }

      this.$store
        .dispatch(CREATE_PROJECT_WIDGET, payload)
        .then(() => {
          this.toast.notify(this, `${this.$t("TOASTS.ADD_WIDGET.MESSAGE")}`, `${this.$t("TOASTS.ADD_WIDGET.TITLE")}`, 'success');
          this.$router.push({ name: 'setup_widgets' });
        })
        .catch(() => {})
        .finally(() => {
          this.submissionStates.submitting = false;
        });
    },
    clearErrors: function() {
      this.$store.dispatch(CLEAR_PROJECT_WIDGET_ERRORS);
    }
  },

  watch:{
    'form.placement': function (){
      if (this.loadingComplete) {
        this.$refs.activityChecklist.reset();
      }
    }
  },

  computed: {
    ...mapGetters([
      "projectWidget",
      "projectWidgets",
      "projectInfo",
      "projectWidgetErrors",
      "systemWidgetTypes",
      "activityList"
    ]),
    noDuplicateTypes: function() {
      return [2,4,6,7];
    },
    loadingComplete: function() {
      return this.pageLoader.getStatus();
    },
    assetReservationData: function() {
      let data = {
        scope: 'project',
        data: {
          directory: 'widgets',
          projectId: this.projectInfo.id
        }
      }

      if (this.managementType == "post") {
        data.data.new = 1;
      } else {
        data.data.projectWidgetId = this.$route.params.id
      }

      return data;
    },
    managementType: function() {
      if (this.$route.params.id != undefined) {
        return "update";
      } else {
        return "post";
      }
    },
    calculatedCardTitle: function() {
      if (this.managementType == "post") {
        return `${this.$t("SETUP.WIDGETS.ADD")}`
      } else {
        return `${this.$t("SETUP.WIDGETS.EDIT")}`
      }
    },
    availableLocations: function() {
      return [
        { id: 1, name: 'Home' },
        { id: 2, name: 'With Activity' }
      ]
    },
    availableWidgetTypes: function() {
      const self = this;
      let returnWidgetTypes = [];

      if (this.form.placement == 1) {
        this.systemWidgetTypes.forEach((systemWidgetType) => {
          if (self.noDuplicateTypes.includes(systemWidgetType.id)) {
            let existingWidgets = this.projectWidgets.filter(function( projectWidget ) {
              return projectWidget.system_widget_types_id == systemWidgetType.id;
            });
            if (existingWidgets.length == 0) {
              returnWidgetTypes.push(systemWidgetType);
            }
          } else {
            returnWidgetTypes.push(systemWidgetType);
          }
        });
      } else {
        return this.systemWidgetTypes.filter(function( obj ) {
          return obj.id != 7
        });
      }

      return returnWidgetTypes;
    },
    eligiblePinActivities: function() {
      let supportedActivityTypes = [1,2,3,4,5,6,8];
      let returnActivities = [];

      if (this.form.placement == 1) {
        returnActivities = [];
      } else {
        returnActivities = this.activityList.filter(function( activity ) {
          return supportedActivityTypes.includes(activity.system_activity_type_id);
        });
      }

      return returnActivities;

    },
    pollActivities: function() {
      return this.activityList.filter(function( obj ) {
        return obj.system_activity_type_id == 9;
      });
    },
    computedvalidation: function() {
      return this.$v.form;
    },
    computedTabs: function() {
      return TopformConfig.topform(this);
    }
  }
};
</script>
